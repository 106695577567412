import { getScrollbarSize } from "@/utilities/scrollbar";
import debounce from "lodash/debounce";
import React, { lazy } from "react";
import { Flipper } from "react-flip-toolkit";
import Helmet from "react-helmet";
import { Navigate, Route, Routes } from "react-router-dom";
import { prefersDarkMode } from "../utilities/style";
import { languageString } from "../utilities/text";
import { getUrl } from "../utilities/url";
import { IS_DOWNTIME } from "../utilities/vars";
import "./app.css";
import CookiePopover from "./assemblies/cookiePopover/CookiePopover";
import DevTools from "./assemblies/devTools/DevTools";
import Downtime from "./assemblies/downtime/Downtime";
import Footer from "./assemblies/footer/Footer";
import ErrorBoundary from "./blocks/errors/errorBoundary/ErrorBoundary";
import ErrorScreen from "./blocks/errors/errorScreen/ErrorScreen";
import { PrivateRoute, PublicRoute, SimpleRoute } from "./blocks/route/Route";
import AppleCallbackPage from "./pages/appleCallback/AppleCallbackPage";
import AuthenticatePage from "./pages/authenticate/AuthenticatePage";
import CampaignAsidePanel from "./pages/campaign/parts/aside/CampaignAsidePanel";
import LandingPage from "./pages/landing/LandingPage";
import TeamInvitesPage from "./pages/team/invites/TeamInvitesPage";

const CampaignAdminPage = lazy(() => import("./pages/campaign/admin/CampaignAdminPage"));
const CampaignAppleStatusPage = lazy(() => import("./pages/campaign/appleStatus/CampaignAppleStatusPage"));
const CampaignBudgetConfigPage = lazy(() => import("./pages/campaign/budgetConfig/CampaignBudgetConfigPage"));
const CampaignDemographicsConfigPage = lazy(
    () => import("./pages/campaign/demographicsConfig/CampaignDemographicsConfigPage")
);
const CampaignEventPage = lazy(() => import("./pages/campaign/event/CampaignEventPage"));
const CampaignEventsPage = lazy(() => import("./pages/campaign/events/CampaignEventsPage"));
const CampaignHistoryPage = lazy(() => import("./pages/campaign/history/CampaignHistoryPage"));
const CampaignKeywordPage = lazy(() => import("./pages/campaign/keyword/CampaignKeywordPage"));
const CampaignKeywordConfigPage = lazy(() => import("./pages/campaign/keywordConfig/CampaignKeywordConfigPage"));
const CampaignKeywordsPage = lazy(() => import("./pages/campaign/keywords/CampaignKeywordsPage"));
const CampaignManageConfigPage = lazy(() => import("./pages/campaign/manageConfig/CampaignManageConfigPage"));
const CampaignMetricsPage = lazy(() => import("./pages/campaign/metrics/CampaignMetricsPage"));
const CampaignProductPagesConfigPageComponent = lazy(
    () => import("./pages/campaign/productPagesConfig/CampaignProductPagesConfigPage")
);
const CampaignRegionConfigPage = lazy(() => import("./pages/campaign/regionConfig/CampaignRegionConfigPage"));
const CampaignRegionsPage = lazy(() => import("./pages/campaign/regions/CampaignRegionsPage"));
const CampaignRuleConfigPage = lazy(() => import("./pages/campaign/ruleConfig/CampaignRuleConfigPage"));
const CampaignScheduleConfigPage = lazy(() => import("./pages/campaign/scheduleConfig/CampaignScheduleConfigPage"));
const CampaignSearchTermsPage = lazy(() => import("./pages/campaign/searchTerms/CampaignSearchTermsPage"));
const CampaignSpendPage = lazy(() => import("./pages/campaign/spend/CampaignSpendPage"));
const CampaignMetricsComparePage = lazy(() => import("./pages/campaign/metricsCompare/CampaignMetricsComparePage"));
const CampaignPage = lazy(() => import("./pages/campaign/CampaignPage"));

const ListingPage = lazy(() => import("./pages/listing/ListingPage"));
const LoginPage = lazy(() => import("./pages/login/LoginPage"));
const PasswordResetPage = lazy(() => import("./pages/passwordReset/PasswordResetPage"));
const PrivacyPolicyPage = lazy(() => import("./pages/privacyPolicy/PrivacyPolicyPage"));
const TermsConditionsPage = lazy(() => import("./pages/termsConditions/TermsConditionsPage"));
const AcceptTermsPage = lazy(() => import("./pages/acceptTerms/AcceptTermsPage"));
const VerifyPage = lazy(() => import("./pages/verify/VerifyPage"));
const CreateAccountPage = lazy(() => import("./pages/createAccount/CreateAccountPage"));
const TeamOverviewPage = lazy(() => import("./pages/teamOverview/TeamOverviewPage"));
const TeamPage = lazy(() => import("./pages/team/TeamPage"));
const AdminPage = lazy(() => import("./pages/admin/AdminPage"));
const ManagementPage = lazy(() => import("./pages/management/ManagementPage"));
const AccountPage = lazy(() => import("./pages/account/AccountPage"));
const TeamInvitePage = lazy(() => import("./pages/teamInvite/TeamInvitePage"));
const CreatePage = lazy(() => import("./pages/create/CreatePage"));
const CampaignsPage = lazy(() => import("./pages/campaigns/CampaignsPage"));
const ContactFormPage = lazy(() => import("./pages/contactForm/ContactFormPage"));
const SupportFormPage = lazy(() => import("./pages/supportForm/SupportFormPage"));
const TierInformationPage = lazy(() => import("./pages/tiersInformation/TierInformationPage"));
const NotFoundPage = lazy(() => import("./pages/404/NotFoundPage"));
const TeamBillingPage = lazy(() => import("./pages/team/billing/TeamBillingPage"));
const EditTeamPage = lazy(() => import("./pages/team/edit/EditTeamPage"));
const TeamHistoryPage = lazy(() => import("./pages/team/history/TeamHistoryPage"));

const ExperimentsPage = lazy(() => import("./pages/experiments/ExperimentsPage"));
const ExperimentCreatePage = lazy(() => import("./pages/experiments/create/ExperimentCreatePage"));
const ExperimentPage = lazy(() => import("./pages/experiments/experiment/ExperimentPage"));
const ExperimentReportPage = lazy(() => import("./pages/experiments/experiment/report/ExperimentsReportPage"));
const ExperimentDualEventsPage = lazy(() => import("./pages/experiments/experiment/events/ExperimentDualEventsPage"));

const ExperimentManagePage = lazy(() => import("./pages/experiments/experiment/manage/ExperimentManagePage"));

const BudgetPage = lazy(() => import("./pages/budget/BudgetPage"));
const ReportsPage = lazy(() => import("./pages/reports/ReportsPage"));
const ImportedCampaignsPage = lazy(() => import("./pages/importCampaigns/ImportedCampaignsPage"));
const ImportedCampaignPage = lazy(() => import("./pages/importCampaigns/importedCampaign/ImportedCampaignPage"));
const ImportedCampaignManagePage = lazy(
    () => import("./pages/importCampaigns/importedCampaign/manage/ImportedCampaignsManagePage")
);
const CompareCampaignsPage = lazy(() => import("./pages/compareCampaigns/CompareCampaignsPage"));

export interface AppProps {
    onResize: (width: number, height: number) => void;
    onScroll: (scrollAmount: number) => void;
    loginRestoring: boolean;
    flipperKey: number;
    error: string;
}

interface AppState {
    theme: "dark" | "light";
}

interface AppRoute {
    url: string;
    title: string;
    description?: string;
    auth: "public" | "private" | "any";
    crumbName?: string;
    crumbUrl?: string;
    element: React.ReactNode;
    hidePrerender?: boolean;
    withNavigation?: boolean;
    asidePanel?: React.ReactElement;
    children?: AppRoute[];
}

class App extends React.PureComponent<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            theme: "dark",
        };
    }

    componentDidMount() {
        this.props.onResize(window.innerWidth, window.innerHeight);
        window.addEventListener(
            "resize",
            debounce(() => {
                this.props.onResize(window.innerWidth, window.innerHeight);
            }, 200)
        );
        window.addEventListener(
            "scroll",
            debounce(() => {
                this.props.onScroll(window.scrollY);
            }, 200)
        );

        // Let the styling know when the mouse is being used
        window.addEventListener("mousedown", function () {
            document.body.classList.add("is-mouse");
        });
        window.addEventListener("keydown", function () {
            document.body.classList.remove("is-mouse");
        });

        setInterval(() => {
            document.body.style.setProperty("--scrollbar-size", `${getScrollbarSize()}px`);
        }, 500);

        const darkMode = prefersDarkMode();
        this.setTheme(darkMode.matches);
        darkMode.addEventListener("change", (e) => this.setTheme(e.matches));
    }

    setTheme(isDark: boolean) {
        const theme = isDark ? "dark" : "light";
        this.setState({
            theme,
        });
        document.body.dataset.theme = theme;
    }

    renderRoute(route: AppRoute) {
        return (
            <Route
                key={route.url}
                path={route.url}
                element={
                    route.auth === "public" ? (
                        <PublicRoute
                            title={route.title}
                            description={route.description}
                            hidePrerender={route.hidePrerender}
                            withNavigation={route.withNavigation}
                            asidePanel={route.asidePanel}
                        >
                            {route.element}
                        </PublicRoute>
                    ) : route.auth === "private" ? (
                        <PrivateRoute
                            title={route.title}
                            description={route.description}
                            hidePrerender={route.hidePrerender}
                            withNavigation={route.withNavigation}
                            asidePanel={route.asidePanel}
                        >
                            {route.element}
                        </PrivateRoute>
                    ) : (
                        <SimpleRoute
                            title={route.title}
                            description={route.description}
                            hidePrerender={route.hidePrerender}
                            withNavigation={route.withNavigation}
                            asidePanel={route.asidePanel}
                        >
                            {route.element}
                        </SimpleRoute>
                    )
                }
            >
                {route.children?.map(this.renderRoute.bind(this))}
            </Route>
        );
    }

    render() {
        if (this.props.error) {
            return <ErrorScreen message={this.props.error} onLeave={() => window.location.reload()} />;
        }

        const routes: AppRoute[] = [
            {
                url: getUrl.home(),
                title: "",
                description: languageString("pages.landing.description"),
                auth: "public",
                crumbName: languageString("breadcrumbs.home"),
                crumbUrl: getUrl.home(),
                element: <LandingPage />,
            },
            {
                url: getUrl.login(),
                title: languageString("login.title"),
                description: languageString("login.description"),
                auth: "any",
                element: <LoginPage />,
            },
            {
                url: getUrl.authenticate(),
                title: languageString("login.authenticating"),
                auth: "any",
                element: <AuthenticatePage />,
            },
            {
                url: getUrl.authenticateXOrigin(),
                title: languageString("login.authenticating"),
                auth: "any",
                element: <AuthenticatePage />,
            },
            {
                url: getUrl.passwordReset(),
                title: languageString("login.reset.title"),
                description: languageString("login.reset.description"),
                auth: "public",
                element: <PasswordResetPage />,
            },
            {
                url: getUrl.acceptTerms(),
                title: languageString("login.termsTitle"),
                auth: "public",
                element: <AcceptTermsPage />,
            },
            {
                url: getUrl.verifyEmail(),
                title: languageString("login.verify.title"),
                auth: "private",
                element: <VerifyPage />,
            },
            {
                url: getUrl.createAccount(),
                title: languageString("login.create.title"),
                description: languageString("login.create.description"),
                auth: "public",
                element: <CreateAccountPage />,
            },
            {
                url: getUrl.teamRoot(),
                title: languageString("pages.team.title"),
                description: languageString("pages.team.description"),
                withNavigation: true,
                auth: "private",
                element: <TeamPage />,
            },
            {
                url: getUrl.team(":teamId"),
                title: languageString("pages.team.title"),
                description: languageString("pages.team.description"),
                withNavigation: true,
                auth: "private",
                element: <TeamPage />,
                children: [
                    {
                        url: getUrl.teamDetailsSuffix(),
                        title: languageString("pages.teamDetails.title"),
                        auth: "private",
                        element: <EditTeamPage />,
                    },
                    {
                        url: getUrl.teamBillingSuffix(),
                        title: languageString("pages.teamBilling.title"),
                        auth: "private",
                        element: <TeamBillingPage />,
                    },
                    {
                        url: getUrl.teamHistorySuffix(),
                        title: languageString("pages.teamHistory.title"),
                        auth: "private",
                        element: <TeamHistoryPage />,
                    },
                    {
                        url: "*",
                        title: "",
                        auth: "private",
                        element: <NotFoundPage />,
                    },
                    {
                        url: "",
                        title: "",
                        auth: "private",
                        element: <Navigate to={getUrl.teamDetailsSuffix()} />,
                    },
                ],
            },
            {
                url: getUrl.teamInvites(),
                title: languageString("pages.teamInvites.title"),
                description: languageString("pages.teamInvites.description"),
                withNavigation: true,
                auth: "private",
                element: <TeamInvitesPage />,
            },
            {
                url: getUrl.appleAuthCallback(),
                title: languageString("pages.appleCallback.title"),
                description: languageString("pages.appleCallback.description"),
                auth: "private",
                element: <AppleCallbackPage />,
            },
            {
                url: getUrl.admin(),
                title: languageString("pages.admin.title"),
                description: languageString("pages.admin.description"),
                withNavigation: true,
                auth: "private",
                element: <AdminPage />,
            },
            {
                url: getUrl.management(),
                title: languageString("pages.management.title"),
                description: languageString("pages.management.description"),
                withNavigation: true,
                auth: "private",
                element: <ManagementPage />,
            },
            {
                url: getUrl.account(),
                title: languageString("pages.account.title"),
                description: languageString("pages.account.description"),
                withNavigation: true,
                auth: "private",
                element: <AccountPage />,
            },
            {
                url: getUrl.invite(":inviteId"),
                title: languageString("pages.teamInvite.title"),
                description: languageString("pages.teamInvite.description"),
                auth: "private",
                element: <TeamInvitePage />,
            },
            {
                url: getUrl.importedCampaign(":teamId", ":campaignId"),
                title: "",
                withNavigation: true,
                auth: "private",
                element: <ImportedCampaignPage />,
                children: [
                    {
                        url: getUrl.campaignMetricsSuffix(),
                        title: "",
                        auth: "private",
                        element: <CampaignMetricsPage />,
                    },
                    {
                        url: getUrl.campaignManageConfigSuffix(),
                        title: "",
                        auth: "private",
                        element: <ImportedCampaignManagePage />,
                    },
                    {
                        url: "",
                        title: "",
                        auth: "private",
                        element: <Navigate to={getUrl.campaignMetricsSuffix()} />,
                    },
                ],
            },
            {
                url: getUrl.campaign(":teamId", ":campaignId"),
                title: "",
                withNavigation: true,
                asidePanel: <CampaignAsidePanel />,
                auth: "private",
                element: <CampaignsPage />,
                children: [
                    {
                        url: "*",
                        title: "",
                        auth: "private",
                        element: <CampaignPage />,
                        children: [
                            {
                                url: getUrl.campaignMetricsSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignMetricsPage />,
                            },
                            {
                                url: getUrl.campaignKeywordsSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignKeywordsPage />,
                            },
                            {
                                url: getUrl.campaignKeywordSuffix(":keywordId"),
                                title: "",
                                auth: "private",
                                element: <CampaignKeywordPage />,
                            },
                            {
                                url: getUrl.campaignRegionsSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignRegionsPage />,
                            },
                            {
                                url: getUrl.campaignEventsSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignEventsPage />,
                            },
                            {
                                url: getUrl.campaignEventSuffix(":eventName"),
                                title: "",
                                auth: "private",
                                element: <CampaignEventPage />,
                            },
                            {
                                url: getUrl.campaignSearchTermsSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignSearchTermsPage />,
                            },
                            {
                                url: getUrl.campaignMetricsCompareSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignMetricsComparePage />,
                            },
                            {
                                url: getUrl.campaignSpendSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignSpendPage />,
                            },
                            {
                                url: getUrl.campaignBudgetConfigSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignBudgetConfigPage />,
                            },
                            {
                                url: getUrl.campaignKeywordConfigSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignKeywordConfigPage />,
                            },
                            {
                                url: getUrl.campaignRegionConfigSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignRegionConfigPage />,
                            },
                            {
                                url: getUrl.campaignDemographicsConfigSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignDemographicsConfigPage />,
                            },
                            {
                                url: getUrl.campaignScheduleConfigSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignScheduleConfigPage />,
                            },
                            {
                                url: getUrl.campaignManageConfigSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignManageConfigPage />,
                            },
                            {
                                url: getUrl.campaignProductPageConfigSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignProductPagesConfigPageComponent />,
                            },
                            {
                                url: getUrl.campaignAppleStatusSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignAppleStatusPage />,
                            },
                            {
                                url: getUrl.campaignHistorySuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignHistoryPage />,
                            },
                            {
                                url: getUrl.campaignRuleConfigSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignRuleConfigPage />,
                            },
                            {
                                url: getUrl.campaignAdminSuffix(),
                                title: "",
                                auth: "private",
                                element: <CampaignAdminPage />,
                            },
                            {
                                url: "*",
                                title: "",
                                auth: "private",
                                element: <NotFoundPage />,
                            },
                            {
                                url: "",
                                title: "",
                                auth: "private",
                                element: <Navigate to={getUrl.campaignMetricsSuffix()} />,
                            },
                        ],
                    },
                ],
            },
            {
                url: getUrl.campaignsRoot(),
                title: languageString("pages.listing.title"),
                description: languageString("pages.listing.description"),
                withNavigation: true,
                auth: "private",
                element: <CampaignsPage />,
                children: [
                    {
                        url: getUrl.campaigns(":teamId"),
                        title: "",
                        auth: "private",
                        element: <ListingPage />,
                    },
                    {
                        url: "*",
                        title: languageString("pages.error404.title"),
                        auth: "private",
                        element: <NotFoundPage />,
                    },
                ],
            },
            {
                url: getUrl.comparisonRoot(),
                title: languageString("pages.comparison.title"),
                description: languageString("pages.comparison.description"),
                withNavigation: true,
                auth: "private",
                element: <CompareCampaignsPage />,
            },
            {
                url: getUrl.comparison(":teamId"),
                title: languageString("pages.comparison.title"),
                description: languageString("pages.comparison.description"),
                withNavigation: true,
                auth: "private",
                element: <CompareCampaignsPage />,
            },
            {
                url: getUrl.overviewRoot(),
                title: languageString("pages.teamOverview.title"),
                description: languageString("pages.teamOverview.description"),
                withNavigation: true,
                auth: "private",
                element: <TeamOverviewPage />,
            },
            {
                url: getUrl.overview(":teamId"),
                title: languageString("pages.teamOverview.title"),
                description: languageString("pages.teamOverview.description"),
                withNavigation: true,
                auth: "private",
                element: <TeamOverviewPage />,
            },
            {
                url: getUrl.budgetRoot(),
                title: languageString("pages.budget.title"),
                description: languageString("pages.budget.description"),
                withNavigation: true,
                auth: "private",
                element: <BudgetPage />,
            },
            {
                url: getUrl.budget(":teamId"),
                title: languageString("pages.budget.title"),
                description: languageString("pages.budget.description"),
                withNavigation: true,
                auth: "private",
                element: <BudgetPage />,
            },
            {
                url: getUrl.reportsRoot(),
                title: languageString("pages.reports.title"),
                description: languageString("pages.reports.description"),
                withNavigation: true,
                auth: "private",
                element: <ReportsPage />,
            },
            {
                url: getUrl.reports(":teamId"),
                title: languageString("pages.reports.title"),
                description: languageString("pages.reports.description"),
                withNavigation: true,
                auth: "private",
                element: <ReportsPage />,
            },
            {
                url: getUrl.experimentCreationStep(":step"),
                title: languageString("pages.create.title"),
                description: languageString("pages.create.description"),
                auth: "private",
                element: <ExperimentCreatePage />,
            },
            {
                url: getUrl.experimentCreation(),
                title: languageString("pages.create.title"),
                description: languageString("pages.create.description"),
                auth: "private",
                element: <ExperimentCreatePage />,
            },
            {
                url: getUrl.experimentsRoot(),
                title: languageString("pages.experiments.title"),
                description: languageString("pages.experiments.description"),
                withNavigation: true,
                auth: "private",
                element: <ExperimentsPage />,
            },
            {
                url: getUrl.experiments(":teamId"),
                title: languageString("pages.experiments.title"),
                description: languageString("pages.experiments.description"),
                withNavigation: true,
                auth: "private",
                element: <ExperimentsPage />,
            },
            {
                url: getUrl.experiment(":teamId", ":experimentId"),
                title: languageString("pages.experiments.title"),
                description: languageString("pages.experiments.description"),
                withNavigation: true,
                auth: "private",
                element: <ExperimentPage />,
                children: [
                    {
                        url: getUrl.experimentResultsSuffix(),
                        title: languageString("pages.experiments.title"),
                        auth: "private",
                        element: <ExperimentReportPage />,
                    },
                    {
                        url: getUrl.experimentEventsSuffix(),
                        title: languageString("pages.experiments.title"),
                        auth: "private",
                        element: <ExperimentDualEventsPage />,
                    },
                    {
                        url: getUrl.campaignManageConfigSuffix(),
                        title: "",
                        auth: "private",
                        element: <ExperimentManagePage />,
                    },
                    {
                        url: "",
                        title: "",
                        auth: "private",
                        element: <Navigate to={getUrl.experimentResultsSuffix()} />,
                    },
                ],
            },
            {
                url: getUrl.experiment(":teamId", ":experimentId"),
                title: languageString("pages.experiments.title"),
                description: languageString("pages.experiments.description"),
                withNavigation: true,
                auth: "private",
                element: <ExperimentPage />,
            },
            {
                url: getUrl.importedCampaignsRoot(),
                title: languageString("pages.importedCampaigns.title"),
                description: languageString("pages.importedCampaigns.description"),
                withNavigation: true,
                auth: "private",
                element: <ImportedCampaignsPage />,
            },
            {
                url: getUrl.importedCampaigns(":teamId"),
                title: languageString("pages.importedCampaigns.title"),
                description: languageString("pages.importedCampaigns.description"),
                withNavigation: true,
                auth: "private",
                element: <ImportedCampaignsPage />,
            },
            {
                url: getUrl.support(),
                title: languageString("pages.supportForm.title"),
                description: languageString("pages.supportForm.description"),
                withNavigation: true,
                auth: "private",
                element: <SupportFormPage />,
            },
            {
                url: getUrl.contact(),
                title: languageString("pages.contactForm.title"),
                description: languageString("pages.contactForm.description"),
                withNavigation: true,
                auth: "any",
                element: <ContactFormPage />,
            },
            {
                url: getUrl.tierInformation(),
                title: "",
                withNavigation: true,
                auth: "any",
                element: <TierInformationPage />,
            },
            {
                url: getUrl.campaignCreationStep(":step"),
                title: languageString("pages.create.title"),
                description: languageString("pages.create.description"),
                auth: "any",
                element: <CreatePage />,
            },
            {
                url: getUrl.campaignCreation(),
                title: languageString("pages.create.title"),
                description: languageString("pages.create.description"),
                auth: "any",
                element: <CreatePage />,
            },
            {
                url: getUrl.terms(),
                title: languageString("pages.terms.title"),
                description: languageString("pages.terms.description"),
                withNavigation: true,
                auth: "any",
                element: <TermsConditionsPage />,
            },
            {
                url: getUrl.privacy(),
                title: languageString("pages.privacy.title"),
                description: languageString("pages.privacy.description"),
                withNavigation: true,
                auth: "any",
                element: <PrivacyPolicyPage />,
            },
            {
                url: getUrl.postRegistration(),
                title: "",
                auth: "any",
                element: <Navigate to={getUrl.campaignsRoot()} />,
            },
            {
                url: "*",
                title: languageString("pages.error404.title"),
                auth: "any",
                element: <NotFoundPage />,
            },
        ];

        return (
            <div id="pageUI">
                <ErrorBoundary>
                    <Helmet>
                        <title>{languageString("ui.title.company", "Redbox Platform")}</title>
                        <meta name="description" content={languageString("ui.description")} />
                        <link rel="icon" type="image/svg+xml" href={`/favicon-${this.state.theme}.svg?v=astra`} />
                    </Helmet>
                    <Flipper
                        flipKey={this.props.flipperKey}
                        spring={{ stiffness: 300, damping: 30 }}
                        staggerConfig={{ default: { speed: 1 } }}
                    >
                        <div className="app" data-login-restoring={this.props.loginRestoring.toString()}>
                            <main className="app-page">
                                {IS_DOWNTIME ? (
                                    <Downtime />
                                ) : (
                                    <Routes>{routes.map(this.renderRoute.bind(this))}</Routes>
                                )}
                            </main>
                            <Footer className="app-footer" />
                        </div>
                    </Flipper>
                    <CookiePopover />
                    <DevTools />
                </ErrorBoundary>
            </div>
        );
    }
}

export default App;
