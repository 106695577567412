import { campaignActions } from "@/actions/campaignActions";
import { toggleNavigationAction } from "@/actions/uiActions";
import { selectCampaigns } from "@/selectors/campaignSelectors";
import { useActionDispatcher, useStateSelector } from "@/store/stateHooks";
import clsx from "clsx";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useMatch, useParams } from "react-router-dom";
import { selectUserPermission, useUserPermission } from "../../../utilities/permissions";
import { languageString } from "../../../utilities/text";
import { isChannelWithKeywords, isChannelWithProductPage } from "../../../utilities/types";
import { getUrl } from "../../../utilities/url";
import ButtonIcon from "../../blocks/buttons/icon/ButtonIcon";
import Icon from "../../blocks/icon/Icon";
import Logo from "../../blocks/logo/Logo";
import Scroller from "../../blocks/scroller/Scroller";
import { useCampaignDataLoader } from "../campaignDataLoader/CampaignDataLoader";
import "./navigation.css";

export interface NavigationProps {
    className?: string;
}
interface TouchState {
    touchX?: number;
    touchY?: number;
}

export default function NavigationComponent({ className }: NavigationProps) {
    const isOpen = useStateSelector((state) => state.ui.navOpen);
    const isLoggedIn = useStateSelector((state) => state.user.isLoggedIn);
    const teamId = useStateSelector((state) => state.ui.activeTeam);

    const permission = useStateSelector((state) => selectUserPermission(state, teamId));

    const onToggle = useActionDispatcher(toggleNavigationAction);

    const [touchState, setTouchState] = useState<TouchState>({ touchX: null, touchY: null });

    const handleTouchStart = useCallback((e: React.TouchEvent<HTMLElement>) => {
        setTouchState({
            touchX: e.touches[0].clientX,
            touchY: e.touches[0].clientY,
        });
    }, []);

    const handleTouchMove = useCallback(
        (e: React.TouchEvent<HTMLElement>) => {
            if (!touchState.touchX || !touchState.touchY) {
                return;
            }

            const x = e.touches[0].clientX;
            const y = e.touches[0].clientY;

            const xDiff = touchState.touchX - x;
            const yDiff = touchState.touchY - y;

            if (Math.max(Math.abs(xDiff), Math.abs(yDiff)) > 20) {
                if (Math.abs(xDiff) > Math.abs(yDiff) && xDiff > 0) {
                    onToggle(false);
                }
                setTouchState({
                    touchX: null,
                    touchY: null,
                });
            }
        },
        [touchState, onToggle]
    );

    const handleTouchEnd = useCallback(
        (e: React.TouchEvent<HTMLElement>) => {
            handleTouchMove(e);
            setTouchState({
                touchX: null,
                touchY: null,
            });
        },
        [handleTouchMove]
    );

    const campaignPageMatch = useMatch(getUrl.campaign(":teamId", ":campaignId") + "/*");
    const isCampaignPage = !!campaignPageMatch;

    const experimentPageMatch = useMatch(getUrl.experiment(":teamId", ":experimentId") + "/*");
    const isExperimentPage = !!experimentPageMatch;

    const importedCampaignsPageMatch = useMatch(getUrl.importedCampaign(":teamId", ":experimentId") + "/*");
    const isImportedCampaignPage = !!importedCampaignsPageMatch;

    return (
        <>
            <div
                onClick={() => onToggle(false)}
                className={clsx("navigation-bg", { "is-open": isOpen })}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <ButtonIcon
                    icon="Close"
                    onClick={() => onToggle(false)}
                    label={languageString("ui.alt.closeNavigation", "Close")}
                    borderless
                />
            </div>
            <nav
                className={clsx(`navigation`, { "is-open": isOpen }, className)}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <Scroller padding={0}>
                    <div className="navigation-menu">
                        <Link
                            to={
                                isLoggedIn
                                    ? teamId
                                        ? getUrl.campaigns(teamId)
                                        : getUrl.campaignsRoot()
                                    : getUrl.home()
                            }
                            className="navigation-logo"
                        >
                            <Logo portrait />
                        </Link>

                        {isCampaignPage ? (
                            <CampaignNavigation />
                        ) : isExperimentPage ? (
                            <ExperimentNavigation />
                        ) : isImportedCampaignPage ? (
                            <ImportedCampaignsNavigation />
                        ) : isLoggedIn ? (
                            <CampaignsNavigation />
                        ) : (
                            <HomeNavigation />
                        )}

                        {isLoggedIn && (
                            <NavGroup title={languageString(`navigation.team.title`)}>
                                {[
                                    {
                                        url: getUrl.teamDetails(teamId ?? 0),
                                        icon: Icon.Group,
                                        name: languageString(`navigation.team.editDetails`),
                                    },
                                    {
                                        url: getUrl.teamBilling(teamId ?? 0),
                                        icon: Icon.Bank,
                                        name: languageString(`navigation.team.manageBilling`),
                                    },
                                    {
                                        url: getUrl.teamHistory(teamId ?? 0),
                                        icon: Icon.History,
                                        name: languageString(`navigation.team.teamHistory`),
                                    },
                                    {
                                        url: getUrl.teamInvites(),
                                        icon: Icon.GroupAdd,
                                        name: languageString(`navigation.team.teamInvites`),
                                    },
                                ]}
                            </NavGroup>
                        )}

                        <NavGroup title={languageString(`navigation.admin.title`)}>
                            {[
                                {
                                    visible: isLoggedIn,
                                    url: getUrl.account(),
                                    icon: Icon.Person,
                                    name: languageString(`navigation.admin.account`),
                                },
                                {
                                    url: isLoggedIn ? getUrl.support() : getUrl.contact(),
                                    icon: Icon.Support,
                                    name: languageString(`navigation.admin.help`),
                                },
                                {
                                    visible: permission.isManager,
                                    url: getUrl.management(),
                                    icon: Icon.Settings,
                                    name: languageString(`navigation.admin.management`),
                                },
                                {
                                    visible: permission.isDeveloper,
                                    url: getUrl.admin(),
                                    icon: Icon.Settings,
                                    name: languageString(`navigation.admin.admin`),
                                },
                            ]}
                        </NavGroup>
                    </div>
                </Scroller>
            </nav>
        </>
    );
}

function NavGroup({
    children,
    title,
}: {
    title: string;
    children: {
        visible?: boolean;
        url: string;
        icon: (typeof Icon)[keyof typeof Icon];
        name: string;
    }[];
}) {
    return (
        <div className="navigation-group">
            <h2 className="navigation-heading">{title}</h2>
            <ul>
                {children
                    .filter((c) => !!c && c.visible !== false)
                    .map((c) => (
                        <li key={c.name}>
                            <NavLink
                                className={({ isActive }) => clsx("navigation-link", { "is-active": isActive })}
                                to={c.url}
                            >
                                <c.icon />
                                {c.name}
                            </NavLink>
                        </li>
                    ))}
            </ul>
        </div>
    );
}

function CampaignNavigation() {
    const { teamId, campaignId } = useParams();
    const loader = useCampaignDataLoader(parseInt(campaignId, 10), parseInt(teamId, 10));
    const permission = useUserPermission();

    const campaign = loader.useCampaign();
    const activeCountries = loader.useCountries().data?.activeCountries ?? [];

    return (
        <>
            <NavGroup title={languageString(`navigation.reporting.title`)}>
                {[
                    {
                        url: getUrl.campaignMetrics(teamId, campaignId),
                        icon: Icon.BarChart,
                        name: languageString(`navigation.reporting.metrics`),
                    },
                    {
                        visible: isChannelWithKeywords(campaign.data?.channelType),
                        url: getUrl.campaignKeywords(teamId, campaignId),
                        icon: Icon.Text,
                        name: languageString(`navigation.reporting.keywords`),
                    },
                    {
                        visible: activeCountries?.length > 1,
                        url: getUrl.campaignRegions(teamId, campaignId),
                        icon: Icon.Globe,
                        name: languageString(`navigation.reporting.regions`),
                    },
                    {
                        url: getUrl.campaignMetricsCompare(teamId, campaignId),
                        icon: Icon.CompareArrows,
                        name: languageString(`navigation.reporting.metricsCompare`),
                    },
                    {
                        url: getUrl.campaignEvents(teamId, campaignId),
                        icon: Icon.Click,
                        name: languageString(`navigation.reporting.events`),
                    },
                    {
                        visible: isChannelWithKeywords(campaign.data?.channelType),
                        url: getUrl.campaignSearchTerms(teamId, campaignId),
                        icon: Icon.SearchList,
                        name: languageString(`navigation.reporting.searchTerms`),
                    },
                    {
                        visible: permission.isManager,
                        url: getUrl.campaignSpend(teamId, campaignId),
                        icon: Icon.Bank,
                        name: languageString(`navigation.reporting.spend`),
                    },
                ]}
            </NavGroup>

            <NavGroup title={languageString(`navigation.config.title`)}>
                {[
                    {
                        url: getUrl.campaignBudgetConfig(teamId, campaignId),
                        icon: Icon.Bank,
                        name: languageString(`navigation.config.budget`),
                    },
                    {
                        visible: permission.canWrite && isChannelWithKeywords(campaign.data?.channelType, null),
                        url: getUrl.campaignKeywordConfig(teamId, campaignId),
                        icon: Icon.Text,
                        name: languageString(`navigation.config.keywords`),
                    },
                    {
                        visible: permission.canWrite,
                        url: getUrl.campaignRegionConfig(teamId, campaignId),
                        icon: Icon.Globe,
                        name: languageString(`navigation.config.regions`),
                    },
                    {
                        url: getUrl.campaignDemographicsConfig(teamId, campaignId),
                        icon: Icon.Target,
                        name: languageString(`navigation.config.demographics`),
                    },
                    {
                        url: getUrl.campaignScheduleConfig(teamId, campaignId),
                        icon: Icon.Schedule,
                        name: languageString(`navigation.config.schedule`),
                    },
                    {
                        visible: permission.canWrite,
                        url: getUrl.campaignManageConfig(teamId, campaignId),
                        icon: Icon.Settings,
                        name: languageString(`navigation.config.manage`),
                    },
                    {
                        visible: isChannelWithProductPage(campaign.data?.channelType),
                        url: getUrl.campaignProductPageConfig(teamId, campaignId),
                        icon: Icon.Devices,
                        name: languageString(`navigation.config.productPages`),
                    },
                    {
                        visible: isChannelWithKeywords(campaign.data?.channelType),
                        url: getUrl.campaignRuleConfig(teamId, campaignId),
                        icon: Icon.Rule,
                        name: languageString(`navigation.config.rules`),
                    },
                    {
                        url: getUrl.campaignHistory(teamId, campaignId),
                        icon: Icon.History,
                        name: languageString(`navigation.config.history`),
                    },
                    {
                        visible: permission.isManager,
                        url: getUrl.campaignAppleStatus(teamId, campaignId),
                        icon: Icon.StatusCheck,
                        name: languageString(`navigation.config.appleStatus`),
                    },
                    {
                        visible: permission.isDeveloper,
                        url: getUrl.campaignAdmin(teamId, campaignId),
                        icon: Icon.Settings,
                        name: languageString(`navigation.config.admin`),
                    },
                ]}
            </NavGroup>
        </>
    );
}

function ExperimentNavigation() {
    const { teamId, experimentId } = useParams();
    const permission = useUserPermission();

    return (
        <>
            <NavGroup title={languageString(`navigation.experiment.title`)}>
                {[
                    {
                        url: getUrl.experimentResults(teamId, experimentId),
                        icon: Icon.BarChart,
                        name: languageString(`navigation.experiment.results`),
                    },
                    {
                        url: getUrl.experimentEvents(teamId, experimentId),
                        icon: Icon.Click,
                        name: languageString(`navigation.experiment.events`),
                    },
                    {
                        visible: permission.canWrite,
                        url: getUrl.experimentManageConfig(teamId, experimentId),
                        icon: Icon.Settings,
                        name: languageString(`navigation.experiment.manage`),
                    },
                ]}
            </NavGroup>
        </>
    );
}

function ImportedCampaignsNavigation() {
    const { teamId, campaignId } = useParams();
    const permission = useUserPermission();

    return (
        <>
            <NavGroup title={languageString(`navigation.imported.title`)}>
                {[
                    {
                        url: getUrl.importedCampaignMetrics(teamId, campaignId),
                        icon: Icon.BarChart,
                        name: languageString(`navigation.imported.metrics`),
                    },
                    {
                        visible: permission.canWrite,
                        url: getUrl.importedCampaignManageConfig(teamId, campaignId),
                        icon: Icon.Settings,
                        name: languageString(`navigation.imported.manage`),
                    },
                ]}
            </NavGroup>
        </>
    );
}

function CampaignsNavigation() {
    const params = useParams();
    let teamId = parseInt(params.teamId, 10);
    if (isNaN(teamId)) {
        teamId = null;
    }

    const campaigns = useStateSelector((state) => selectCampaigns(state, teamId));
    const loadCampaigns = useActionDispatcher(campaignActions.list.request);
    useEffect(() => {
        if (teamId && !campaigns.isRequesting && !campaigns.success && !campaigns.errorMessage) {
            loadCampaigns(teamId);
        }
    }, [teamId]);

    return (
        <NavGroup title={languageString(`navigation.campaigns.title`)}>
            {[
                {
                    url: getUrl.campaigns(teamId ?? 0),
                    icon: Icon.List,
                    name: languageString(`navigation.campaigns.listing`),
                },
                {
                    url: getUrl.budget(teamId ?? 0),
                    icon: Icon.Bank,
                    name: languageString(`navigation.campaigns.budget`),
                    visible: campaigns.data?.length > 0,
                },
                {
                    url: getUrl.overview(teamId ?? 0),
                    icon: Icon.BarChart,
                    name: languageString(`navigation.campaigns.overview`),
                    visible: campaigns.data?.length > 0,
                },
                {
                    url: getUrl.comparison(teamId ?? 0),
                    icon: Icon.Comparison,
                    name: languageString(`navigation.campaigns.comparison`),
                    visible: campaigns.data?.length > 1,
                },
                {
                    url: getUrl.reports(teamId ?? 0),
                    icon: Icon.Metrics,
                    name: languageString(`navigation.campaigns.reports`),
                },
                {
                    url: getUrl.experiments(teamId ?? 0),
                    icon: Icon.Science,
                    name: languageString(`navigation.campaigns.experiments`),
                },
                {
                    url: getUrl.importedCampaigns(teamId ?? 0),
                    icon: Icon.MoveDown,
                    name: languageString(`navigation.campaigns.importedCampaigns`),
                },
            ]}
        </NavGroup>
    );
}

function HomeNavigation() {
    return (
        <NavGroup title={languageString(`navigation.home.title`)}>
            {[
                {
                    url: getUrl.home(),
                    icon: Icon.Home,
                    name: languageString(`navigation.home.home`),
                },
                {
                    url: getUrl.tierInformation(),
                    icon: Icon.Quote,
                    name: languageString(`navigation.home.tiers`),
                },
                {
                    url: getUrl.privacy(),
                    icon: Icon.Article,
                    name: languageString(`navigation.home.privacy`),
                },
                {
                    url: getUrl.terms(),
                    icon: Icon.Article,
                    name: languageString(`navigation.home.terms`),
                },
            ]}
        </NavGroup>
    );
}
