import {
    type RubyCampaignId,
    type RubyKeyword,
    type RubyTeamId,
    type RubyTeamInvite,
} from "../services/backend/RubyData";
type TeamId = RubyTeamId | string;
type CampaignId = RubyCampaignId | string;
type KeywordId = RubyKeyword["id"] | string;
type InviteId = RubyTeamInvite["id"] | string;

const getCampaignsRoot = () => "/campaigns";
const getCampaignsUrl = (teamId: TeamId) => `${getCampaignsRoot()}/${teamId}`;
const getCampaignUrl = (teamId: TeamId, campaignId: CampaignId) => `${getCampaignsUrl(teamId)}/${campaignId}`;
const getCampaignSubPageUrl = (page: string) => (teamId: TeamId, campaignId: CampaignId) =>
    `${getCampaignUrl(teamId, campaignId)}/${page}`;

const getTeamRoot = () => "/team";
const getTeamUrl = (teamId: TeamId) => `${getTeamRoot()}/${teamId}`;
const getTeamSubPage = (page: string) => (teamId: TeamId) => `${getTeamUrl(teamId)}/${page}`;

const getExperimentsRoot = () => "/experiments";
const getExperimentsUrl = (teamId: TeamId) => `${getExperimentsRoot()}/${teamId}`;
const getExperimentUrl = (teamId: TeamId, experimentId: CampaignId) => `${getExperimentsUrl(teamId)}/${experimentId}`;
const getExperimentSubPageUrl = (page: string) => (teamId: TeamId, experimentId: CampaignId) =>
    `${getExperimentUrl(teamId, experimentId)}/${page}`;

const getImportedCampaignsRoot = () => "/imported-campaigns";
const getImportedCampaignsUrl = (teamId: TeamId) => `${getImportedCampaignsRoot()}/${teamId}`;
const getImportedCampaignUrl = (teamId: TeamId, importId: CampaignId) =>
    `${getImportedCampaignsUrl(teamId)}/${importId}`;
const getImportedCampaignSubPageUrl = (page: string) => (teamId: TeamId, importId: CampaignId) =>
    `${getImportedCampaignUrl(teamId, importId)}/${page}`;

const campaignMetricsSuffix = () => "metrics";
const campaignRegionsSuffix = () => "regions";
const campaignKeywordsSuffix = () => "keywords";
const campaignKeywordSuffix = (keywordId: KeywordId) => `keywords/${keywordId}`;
const campaignEventsSuffix = () => "events";
const campaignEventSuffix = (eventName: string) => `events/${eventName}`;
const campaignHistorySuffix = () => "config/history";
const campaignBudgetConfigSuffix = () => "config/budget";
const campaignKeywordConfigSuffix = () => "config/keywords";
const campaignRegionConfigSuffix = () => "config/regions";
const campaignDemographicsConfigSuffix = () => "config/demographics";
const campaignManageConfigSuffix = () => "config/manage";
const campaignProductPageConfigSuffix = () => "config/product-pages";
const campaignRuleConfigSuffix = () => "config/rules";
const campaignScheduleConfigSuffix = () => "config/schedule";
const campaignSpendSuffix = () => "spend";
const campaignSearchTermsSuffix = () => "search-terms";
const campaignAppleStatusSuffix = () => "config/status";
const campaignAdminSuffix = () => "config/admin";
const campaignMetricsCompareSuffix = () => "metrics-compare";

const experimentResultsSuffix = () => "results";
const experimentEventsSuffix = () => "events";

const teamDetailsSuffix = () => "details";
const teamBillingSuffix = () => "billing";
const teamHistorySuffix = () => "history";

export const getUrl = {
    home: () => "/",

    login: (redirect?: string) => "/login" + (redirect ? `?redirect=${redirect}` : ""),
    authenticate: () => "/authenticate",
    authenticateXOrigin: () => "/authenticate/xorigin",
    passwordReset: () => "/password-reset",
    acceptTerms: () => "/accept-terms",
    verifyEmail: () => "/verify",
    createAccount: () => "/create-account",
    postRegistration: () => "/post-registration",

    teamRoot: getTeamRoot,
    team: getTeamUrl,
    teamDetails: getTeamSubPage(teamDetailsSuffix()),
    teamBilling: getTeamSubPage(teamBillingSuffix()),
    teamHistory: getTeamSubPage(teamHistorySuffix()),

    teamDetailsSuffix,
    teamBillingSuffix,
    teamHistorySuffix,

    teamInvites: () => `/invites`,
    appleAuthCallback: () => `/apple-callback`,
    teamReports: () => "/reports",

    admin: () => "/admin",
    management: () => "/management",
    account: () => "/account",
    invite: (inviteId: InviteId) => `/invite/${inviteId}`,

    campaignsRoot: getCampaignsRoot,
    campaigns: getCampaignsUrl,
    campaign: getCampaignUrl,

    comparisonRoot: () => `/comparison`,
    overviewRoot: () => `/overview`,
    overview: (teamId: TeamId) => `/overview/${teamId}`,
    comparison: (teamId: TeamId) => `/comparison/${teamId}`,

    budgetRoot: () => `/budget`,
    budget: (teamId: TeamId) => `/budget/${teamId}`,

    reportsRoot: () => "/reports",
    reports: (teamId: TeamId) => `/reports/${teamId}`,
    reportFileCacheRoot: () => `/cache/report`,
    reportFileCache: (fileName: string) => `/cache/report/${fileName}`,

    experimentsRoot: getExperimentsRoot,
    experiments: getExperimentsUrl,
    experimentCreation: () => `${getExperimentsRoot()}/create`,
    experimentCreationStep: (step: string) => `${getExperimentsRoot()}/create/${step}`,
    experiment: getExperimentUrl,
    experimentResults: getExperimentSubPageUrl(experimentResultsSuffix()),
    experimentEvents: getExperimentSubPageUrl(experimentEventsSuffix()),
    experimentManageConfig: getExperimentSubPageUrl(campaignManageConfigSuffix()),

    importedCampaignsRoot: getImportedCampaignsRoot,
    importedCampaigns: getImportedCampaignsUrl,
    importedCampaign: getImportedCampaignUrl,
    importedCampaignMetrics: getImportedCampaignSubPageUrl(campaignMetricsSuffix()),
    importedCampaignManageConfig: getImportedCampaignSubPageUrl(campaignManageConfigSuffix()),

    campaignMetrics: getCampaignSubPageUrl(campaignMetricsSuffix()),
    campaignRegions: getCampaignSubPageUrl(campaignRegionsSuffix()),
    campaignKeywords: getCampaignSubPageUrl(campaignKeywordsSuffix()),
    campaignHistory: getCampaignSubPageUrl(campaignHistorySuffix()),
    campaignKeyword: (teamId: TeamId, campaignId: CampaignId, keywordId: KeywordId) =>
        getCampaignSubPageUrl(campaignKeywordSuffix(keywordId))(teamId, campaignId),
    campaignEvents: getCampaignSubPageUrl(campaignEventsSuffix()),
    campaignEvent: (teamId: TeamId, campaignId: CampaignId, eventName: string) =>
        getCampaignSubPageUrl(campaignEventSuffix(eventName))(teamId, campaignId),
    campaignBudgetConfig: getCampaignSubPageUrl(campaignBudgetConfigSuffix()),
    campaignKeywordConfig: getCampaignSubPageUrl(campaignKeywordConfigSuffix()),
    campaignRegionConfig: getCampaignSubPageUrl(campaignRegionConfigSuffix()),
    campaignDemographicsConfig: getCampaignSubPageUrl(campaignDemographicsConfigSuffix()),
    campaignManageConfig: getCampaignSubPageUrl(campaignManageConfigSuffix()),
    campaignProductPageConfig: getCampaignSubPageUrl(campaignProductPageConfigSuffix()),
    campaignRuleConfig: getCampaignSubPageUrl(campaignRuleConfigSuffix()),
    campaignScheduleConfig: getCampaignSubPageUrl(campaignScheduleConfigSuffix()),
    campaignSpend: getCampaignSubPageUrl(campaignSpendSuffix()),
    campaignSearchTerms: getCampaignSubPageUrl(campaignSearchTermsSuffix()),
    campaignAppleStatus: getCampaignSubPageUrl(campaignAppleStatusSuffix()),
    campaignAdmin: getCampaignSubPageUrl(campaignAdminSuffix()),
    campaignMetricsCompare: getCampaignSubPageUrl(campaignMetricsCompareSuffix()),

    campaignMetricsSuffix,
    campaignRegionsSuffix,
    campaignKeywordsSuffix,
    campaignHistorySuffix,
    campaignKeywordSuffix,
    campaignEventsSuffix,
    campaignEventSuffix,
    campaignBudgetConfigSuffix,
    campaignKeywordConfigSuffix,
    campaignRegionConfigSuffix,
    campaignDemographicsConfigSuffix,
    campaignManageConfigSuffix,
    campaignProductPageConfigSuffix,
    campaignRuleConfigSuffix,
    campaignScheduleConfigSuffix,
    campaignSpendSuffix,
    campaignSearchTermsSuffix,
    campaignAppleStatusSuffix,
    campaignAdminSuffix,
    campaignMetricsCompareSuffix,

    experimentResultsSuffix,
    experimentEventsSuffix,

    campaignCreation: () => "/create",
    campaignCreationStep: (step: string) => `/create/${step}`,

    tierInformation: () => "/tier-information",

    terms: () => "/terms-and-conditions",
    privacy: () => "/privacy-policy",
    support: () => "/support",
    contact: () => "/contact",
};

export function getPostLoginUrl() {
    return getCampaignsRoot();
}
